import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Breadcrumb from '../components/breadcrumbs'
import { FormControl, FormGroup, FormControlLabel } from "@material-ui/core"
import Modal from 'react-modal'
import ReactPaginate from 'react-paginate'

const InventoryPage = props => {
    const { data } = props
    const allPosts = data.allContentfulProduct.edges

    const productData = allPosts && allPosts.map(({ node: post }) => (
        {
            id: '/inventory' + post.slug,
            title: post.title,
            category: post.category,
            description: post.body.body,
            mileshours: post.mileshours,
            StockNumber: post.StockNumber,
            images: post.images
        }
    ))

    const product = allPosts && allPosts.map(({ node: post }) => (
        {
            id: '/inventory' + post.slug,
            title: post.title,
            category: post.category,
            description: post.body.body,
            mileshours: post.mileshours,
            StockNumber: post.StockNumber,
            images: post.images
        }
    ))

    const [category, setCategory] = useState([])
    const [filteredCategory, setFilteredCategory] = useState([])

    const isduplicate = product.map(o => o.category)
    const filteredCheckbox = product.filter(({ category }, index) => !isduplicate.includes(category, index + 1))

    const isduplicateFilter = filteredCategory.map(o => o.category)
    const filteredFilter = filteredCategory.filter(({ category }, index) => !isduplicateFilter.includes(category, index + 1))

    const handleChangeNew = e => {
        if (e.target.checked) {
            setCategory([...category, e.target.value])
        }
        else {
            setCategory(category.filter(id => id !== e.target.value))
        }
    }

    useEffect(() => {
        if (category.length === 0) {
            setFilteredCategory(productData)
        }
        else {
            setFilteredCategory(
                productData.filter(product =>
                    category.some(category => [product.category].flat().includes(category))
                )
            )
        }
        // DO NOT DELETE THE LINE BELOW, IT IS NOT A COMMENT & IS BEING USED BY THE CODE
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category])

    let isEmpty = false
    let titleCategory = category
    if (titleCategory.length < 1) {
        isEmpty = true
    }
    else {
        isEmpty = false
    }

    let obj = {}
    product.forEach((item) => {
        if (!obj[item.category]) {
            obj[item.category] = 1
        }
        else {
            obj[item.category] += 1
        }
    })

    function ClearAll() {
        document.location.reload(true)
    }

    const [modalIsOpen, setIsOpen] = React.useState(false)

    function openModal() {
        setIsOpen(true)
    }

    function closeModal() {
        setIsOpen(false)
    }

    const style = {
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '12px',
            outline: 'none',
            padding: '20px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-40%',
            transform: 'translate(-50%, -50%)',
            height: '90%',
            width: '90%'
        },
        overlay: {
            backdropFilter: 'blur(1px)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 1000
        }
    }

    const [checkedState, setCheckedState] = useState(
        new Array(filteredCheckbox.length).fill(false)
    )

    var resultCount
    function resultCounter() {
        if (checkedState && !isEmpty) {
            resultCount = "(" + filteredCategory.length + ")"
        }
        else {
            resultCount = ""
        }
        return resultCount
    }

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item)

        setCheckedState(updatedCheckedState)
    }

    function shorten(str, maxLen, separator = '') {
        if (str.length <= maxLen) return str;
        return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
    }

    const items = filteredCategory.map((product) => {
        const image = getImage(product.images[0])
        return (
            <div className="flex flex-col mx-auto border rounded-lg shadow-xl mb-3 bg-white" key={product.slug}>
                <Link to={product.id} title={product.title} alt={product.title}>
                    <GatsbyImage className="rounded-t-lg h-56 z-0 hover:opacity-70 transition duration-100 ease-in-out" src="" image={image} title={product.images[0].title} alt={product.images[0].description} />
                </Link>
                <article>
                    <div className="max-w-md mx-auto rounded-lg h-72 xl:h-[18.5rem] 2xl:h-72">
                        <div className="px-5 py-4">
                            <Link to={product.id} title={product.title} alt={product.title}>
                                <div className="hover:opacity-60 h-28 xl:h-32 2xl:h-28 transition duration-100 ease-in-out">
                                    <h3 className="font-bold md:hidden">{shorten(product.title, 65)}</h3>
                                    <h3 className="font-bold hidden md:block lg:hidden">{shorten(product.title, 85)}</h3>
                                    <h3 className="font-bold hidden lg:block 2xl:hidden">{shorten(product.title, 65)}</h3>
                                    <h3 className="font-bold hidden 2xl:block">{shorten(product.title, 73)}</h3>
                                    <p className="py-3 text-sm text-gray-500 md:hidden">{shorten(product.description, 73)}</p>
                                    <p className="py-3 text-sm text-gray-500 hidden md:block lg:hidden">{shorten(product.description, 150)}</p>
                                    <p className="py-3 text-sm text-gray-500 hidden lg:block 2xl:hidden">{shorten(product.description, 90)}</p>
                                    <p className="py-3 text-sm text-gray-500 hidden 2xl:block">{shorten(product.description, 120)}</p>
                                </div>
                            </Link>
                            <hr className="my-3 lg:mt-4" />

                            <div className="flex justify-between">
                                <div className="bg-[#0CAFA9] bg-opacity-20 w-fit h-fit px-2 mt-5 rounded inline">
                                    <span className="text-[#0CAFA9] text-sm">View For Price</span>
                                </div>

                                <div className=" w-fit px-2 rounded inline space-x-5 flex">
                                    <div>
                                        <span className="text-gray-400 text-xs">Miles/Hours</span>
                                        <p className="font-medium text-sm xl:text-xs 2xl:text-sm">{product.mileshours}</p>
                                    </div>

                                    <div>
                                        <span className="text-gray-400 text-xs">Stock</span>
                                        <p className="font-medium text-sm xl:text-xs 2xl:text-sm">{product.StockNumber}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="pt-7 pb-3 lg:pt-5 xl:pt-6">
                                <Link to={product.id}>
                                    <button className="relative w-full h-10 py-2 px-5 mx-auto transition-colors rounded-lg focus:shadow-outline bg-gray-200 text-white hover:bg-orange shadow-lg font-semibold text-orange hover:text-white transition duration-150 ease-in-out" title='Show More Details' alt='Iron West Group - Show More Details'>
                                        <span>More Details</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    })

    function Items({ currentItems }) {
        return (
            <div className="mx-auto grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-8 sm:w-full -ml-8 -mr-4 sm:-ml-2 md:-ml-0">
                {currentItems && currentItems.map((item) => (
                    <div>
                        {item}
                    </div>
                ))}
            </div>
        )
    }

    function PaginatedItems({ itemsPerPage }) {
        const [currentItems, setCurrentItems] = useState(null)
        const [pageCount, setPageCount] = useState(0)
        const [itemOffset, setItemOffset] = useState(0)

        useEffect(() => {
            const endOffset = itemOffset + itemsPerPage
            setCurrentItems(items.slice(itemOffset, endOffset))
            setPageCount(Math.ceil(items.length / itemsPerPage))
        }, [itemOffset, itemsPerPage])

        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % items.length
            setItemOffset(newOffset)
        }

        function topFunction() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
        return (
            <>
                <Items currentItems={currentItems} />
                <div className="flex justify-center pt-10 -ml-4 md:-ml-0">
                    <ReactPaginate
                        nextLabel={
                            <svg className="h-8 w-8 border rounded-full bg-gray-300 p-2 hover:bg-orange hover:text-white transition duration-200 ease-in-out ml-1 md:ml-0" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L8 8L1 15" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        }
                        onClick={topFunction}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                            <svg className="h-8 w-8 border rounded-full bg-gray-300 p-2 hover:bg-orange hover:text-white transition duration-200 ease-in-out mr-1 md:mr-0" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 1L0.999999 8L8 15" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        }
                        pageClassName="pt-1"
                        pageLinkClassName="border border-lightgray px-2 md:px-3 py-1 rounded-lg hover:border hover:bg-orange hover:bg-opacity-50 transition duration-150 ease-in-out"
                        previousClassName=""
                        previousLinkClassName=""
                        nextClassName=""
                        nextLinkClassName=""
                        breakLabel="..."
                        breakClassName="pt-1"
                        breakLinkClassName="border border-lightgray px-3 py-1 rounded-lg hover:border hover:bg-orange hover:bg-opacity-50 transition duration-150 ease-in-out"
                        containerClassName="flex md:space-x-2"
                        activeLinkClassName="text-orange hover:bg-lightgray"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </>
        )
    }

    return (
        <div>
            <Layout>
                <Seo
                    title="Inventory - View Affordable Heavy Equipment Sales"
                    description="Affordable Sacramento Heavy Equipment Sales. Iron West Group Specializes In Selling Affordable Heavy Equipment, Commercial Equipment, Construction Equipment, Attachment's, And Much More."
                    keywords={["Sacramento Affordable Heavy Equipment Sales"]}
                    location="/inventory"
                    isInventoryPage={true}
                />
                <div className="bg-lightgray">
                    <div className="pl-2 md:pl-4 pt-10 lg:pt-5">
                        <nav className="flex container mx-auto px-4 py-1 md:py-3" aria-label="Breadcrumb">
                            <Breadcrumb links={[
                                { to: '/', label: 'Home' },
                                { to: '/inventory', label: 'Inventory' },
                            ]} />
                        </nav>
                    </div>
                    <div className="container mx-auto">
                        <div className="pt-2 lg:pt-5">
                            <h1 className="text-3xl font-bold mx-auto px-6">
                                Inventory
                            </h1>
                            <p className="md:hidden pt-3 font-medium mx-auto px-6 text-sm">Showing <b>{filteredCategory.length}</b> Result(s)</p>
                        </div>

                        <div className="md:hidden">
                            <div className="px-6 pt-6">
                                <button onClick={openModal} className="h-10 py-2 px-5 w-full mx-auto transition-colors rounded-lg border border-orange focus:shadow-outline bg-orange text-white hover:shadow-lg hover:shadow-orange font-semibold transition duration-150 ease-in-out" title='Filter Categories' alt='Iron West Group - Filter Categories'>
                                    <span>Filter Categories</span>
                                </button>
                            </div>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Mobile Filter Modal"
                                style={style}
                                ariaHideApp={false}
                            >
                                <div className="pb-2">
                                    <p className="text-orange text-2xl">Categories</p>
                                    <hr className="mt-4 mb-3" />
                                    <div className="py-3 pr-5 -mr-5">
                                        <button onClick={ClearAll} className="h-10 py-2 px-5 w-full mx-auto transition-colors rounded-lg border border-orange focus:shadow-outline bg-orange text-white hover:shadow-lg hover:shadow-orange font-semibold transition duration-150 ease-in-out" title='Clear All Selections' alt='Iron West Group - Clear All Selections'>
                                            <span>Clear All</span>
                                        </button>
                                    </div>
                                    <div className="w-[14rem] px-3 space-y-3">
                                        <p className="uppercase font-medium -ml-[0.7rem] text-sm text-orange pt-3 -mb-2">All Categories: </p>
                                        <hr className="-ml-3 w-[7.8rem] border-1 border-gray-200" />

                                        {filteredCheckbox.map((product, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <input
                                                        type="checkbox"
                                                        id={`custom-checkbox-${index}`}
                                                        name={product.category}
                                                        value={product.category}
                                                        checked={checkedState[index]}
                                                        onChange={() => handleOnChange(index)}
                                                        onClick={handleChangeNew}
                                                        className="appearance-none checkmark w-5 h-5 bg-white border border-black rounded focus:outline-none checked:bg-orange hover:shadow-gray-400 hover:shadow-md checked:hover:shadow-md checked:hover:shadow-orange checked:border-orange mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                    />
                                                }
                                                label={
                                                    <div className="pt-1">
                                                        <div className="inline pr-2">
                                                            <span>{product.category} </span>
                                                        </div>

                                                        <div className="bg-orange rounded-md inline px-[0.4rem] py-[0.1rem] text-sm">
                                                            <span> {obj[product.category]}</span>
                                                        </div>
                                                    </div>}
                                                value={product.category}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div className="fixed top-6 right-5">
                                    <button className="text-lg font-bold hover:text-orange transition duration-100 ease-in-out" onClick={closeModal}>X</button>
                                </div>

                                <div className="flex justify-center mx-auto">
                                    <hr className="fixed bottom-20 border-1 w-full" />
                                    <div className="fixed bottom-7 space-x-5">

                                        <button onClick={closeModal} className="relative py-1 px-4 text-sm mx-auto transition-colors rounded-lg border border-orange focus:shadow-outline bg-orange text-white hover:shadow-lg hover:shadow-orange font-semibold transition duration-150 ease-in-out" title="Close" alt='Close'>
                                            <span>Close</span>
                                        </button>

                                        <button onClick={closeModal} className="relative py-1 px-4 text-sm mx-auto transition-colors rounded-lg border border-orange focus:shadow-outline bg-orange text-white hover:shadow-lg hover:shadow-orange font-semibold transition duration-150 ease-in-out" title='Show Results' alt='Iron West Group - Show Results'>
                                            <span>Show Results {resultCounter()}</span>
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                        </div>

                        <div className="container mx-auto py-2 pb-14 px-10">
                            <div className="mx-auto flex">
                                <div className="-ml-4 pr-8">
                                    <div className="hidden md:block pb-5">
                                        <p className="pb-2 font-medium">Showing <b>{filteredCategory.length}</b> Result(s)</p>
                                        <button onClick={ClearAll} className="relative py-1 px-2 text-sm mx-auto transition-colors rounded-lg border border-orange focus:shadow-outline bg-orange text-white hover:shadow-lg hover:shadow-orange font-semibold transition duration-150 ease-in-out" title='Clear All' alt='Iron West Group - Clear All'>
                                            <span>Clear All</span>
                                        </button>
                                    </div>
                                    <FormControl>
                                        <FormGroup>
                                            <div className="hidden md:block bg-white w-[14rem] xl:w-[14.8rem] space-y-4 rounded-lg shadow-xl md:pl-5 pb-5">
                                                <p className="text-orange text-lg pt-5 -mb-4">Browse Categories:</p>
                                                <hr className="w-3/4 border-1 border-gray-200" />
                                                {filteredCheckbox.map((product, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        control={<input
                                                            type="checkbox"
                                                            id={`custom-checkbox-${index}`}
                                                            name={product.category}
                                                            value={product.category}
                                                            checked={checkedState[index]}
                                                            onChange={() => handleOnChange(index)}
                                                            onClick={handleChangeNew}
                                                            className="appearance-none checkmark w-5 h-5 bg-white border-2 border-black rounded focus:outline-none checked:bg-orange checked:border-orange hover:shadow-gray-400 hover:shadow-lg checked:hover:shadow-lg checked:hover:shadow-orange mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                        />}
                                                        label={
                                                            <div className="pt-1">
                                                                <div className="inline pr-2 px-2">
                                                                    <span>{product.category} </span>
                                                                </div>

                                                                <div className="bg-orange rounded-md inline px-[0.4rem] py-[0.1rem] text-sm">
                                                                    <span> {obj[product.category]}</span>
                                                                </div>
                                                            </div>}
                                                        value={product.category}
                                                    />
                                                ))}
                                            </div>
                                        </FormGroup>
                                    </FormControl>
                                </div>
                                <div className="mx-auto">
                                    <div className="pb-5 -ml-8 sm:-ml-3 md:-ml-0">
                                        {isEmpty ? (
                                            <div>
                                                <p className="text-xl md:text-2xl font-medium pt-3 md:pt-1">Showing - All Inventory</p>
                                            </div>
                                        ) : (
                                            filteredFilter.map(product => {
                                                return (
                                                    <div key={product.id} className="px-1 py-1 inline-grid pt-4">
                                                        <div className="bg-orange rounded py-1">
                                                            <span className="px-3 text-sm">{product.category}</span>
                                                            {/* <button className="">
                                                                <span className="text-black text-lg outline-none focus:outline-none hover:text-white">×</span>
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )}
                                    </div>

                                    <hr className="border border-orange mb-6 -ml-7 -mr-4 sm:mr-2 sm:-ml-2 md:ml-1 md:-mr-0" />
                                    <div>
                                        <PaginatedItems itemsPerPage={9} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default InventoryPage
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulProduct(sort: { fields: category, order: ASC }) {
        edges{
            node{
                slug
                title
                body {
                    body
                }
                category
                mileshours
                StockNumber
                images {
                    id
                    description
                    gatsbyImageData
                    url
                    title
                }
            }
        }
    }
}
`